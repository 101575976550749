import * as React from "react"
import { Link, useStaticQuery } from "gatsby"
import { createStyles, withStyles } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import ListSubheader from "@material-ui/core/ListSubheader"
import ClearAllIcon from "@material-ui/icons/ClearAll"
import TranslateIcon from "@material-ui/icons/Translate"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Switch from "@material-ui/core/Switch"

// Styles
const styles = theme =>
  createStyles({
    innerDrawerWrapper: {
      backgroundColor: "inherit",
    },
    toolbar: {
      ...theme.mixins.toolbar,
      backgroundColor: "inherit",
    },
    menu: {
      backgroundColor: "inherit",
      paddingTop: theme.spacing(12),
    },
    subheader: {
      position: 'sticky !important',
      top: 48,
    },
    div: {
      marginTop: 16,
    },
  })

const DrawerInner = props => {
  const { classes, handleDrawerClose } = props

  const data = useStaticQuery(graphql`
    query {
      allGoogleDocs(sort: { fields: document___name }) {
        nodes {
          document {
            path
            name
          }
        }
      }
    }
  `)

  const nodes = data.allGoogleDocs.nodes

  const enStudents = []
  const enTeachers = []
  const deStudents = []
  const deTeachers = []

  nodes.forEach(item => {
    const path = item.document.path

    switch (path.substr(0, 5)) {
      case "/de/l":
        deTeachers.push(item)
        break
      case "/de/s":
        deStudents.push(item)
        break
      case "/en/t":
        enTeachers.push(item)
        break
      case "/en/s":
        enStudents.push(item)
        break
      default:
        break
    }
  })

  const [isGerman, setIsGerman] = React.useState(true)

  const handleLanguageToggle = () => {
    setIsGerman(!isGerman)
  }

  const renderItems = items =>
    items.map(item => (
      <ListItem
        button
        key={item.document.path}
        component={Link}
        {...{ to: item.document.path, onClick: handleDrawerClose }}
      >
        <ListItemIcon>
          <ClearAllIcon />
        </ListItemIcon>
        <ListItemText primary={item.document.name.substr(4)} />
      </ListItem>
    ))

  return (
    <div className={classes.innerDrawerWrapper}>
      <div className={classes.toolbar} />
      <List className={classes.menu} dense>
        <ListSubheader className={classes.subheader}>Sprache / Language</ListSubheader>
        <ListItem>
          <ListItemIcon>
            <TranslateIcon />
          </ListItemIcon>
          <ListItemText primary="Deutsch" />
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              onChange={handleLanguageToggle}
              checked={isGerman}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <TranslateIcon />
          </ListItemIcon>
          <ListItemText primary="English" />
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              onChange={handleLanguageToggle}
              checked={!isGerman}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider className={classes.div} />

        {isGerman && (
          <>
            <ListSubheader className={classes.subheader}>Lehrpersonen</ListSubheader>
            {renderItems(deTeachers)}
            <Divider className={classes.div} />

            <ListSubheader className={classes.subheader}>Schüler*innen</ListSubheader>
            {renderItems(deStudents)}
          </>
        )}

        {!isGerman && (
          <>
            <ListSubheader className={classes.subheader}>Teachers</ListSubheader>
            {renderItems(enTeachers)}
            <Divider className={classes.div} />

            <ListSubheader className={classes.subheader}>Students</ListSubheader>
            {renderItems(enStudents)}
          </>
        )}
      </List>
    </div>
  )
}

export default withStyles(styles)(DrawerInner)
