import * as React from "react"
import { Link } from "gatsby"
import clsx from "clsx"
import { createStyles, withStyles } from "@material-ui/core"
import AppBar from "@material-ui/core/AppBar"
import IconButton from "@material-ui/core/IconButton"
import Toolbar from "@material-ui/core/Toolbar"
import MenuIcon from "@material-ui/icons/Menu"
import DrawerInner from "../../src/components/drawerInner"
import Container from "@material-ui/core/Container"
import Hidden from "@material-ui/core/Hidden"
import Typography from "@material-ui/core/Typography"
import Drawer from "@material-ui/core/Drawer"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import { useHover } from "./hooks"
import Logo from "./instrumentor-white-min.svg"

const drawerWidth = 300
const width = 300

// Assets
// const logo = require('svg-inline-loader?classPrefix!Assets/instrumentor-white-min.svg')

const arrow = theme => ({
  [theme.breakpoints.up("md")]: {
    position: "relative",
    "&:before": {
      content: '"→"',
      display: "inline-block",
      left: -64,
      position: "absolute",
      textAlign: "right",
      top: "-0.06em",
      width: 48,
    },
  },
})

const li1 = _ => ({
  position: "relative",
  "&:before": {
    content: '"★"',
    display: "inline-block",
    left: -64,
    position: "absolute",
    textAlign: "right",
    // top: "-0.06em",
    width: 56,
  },
})

const li2 = _ => ({
  position: "relative",
  "&:before": {
    content: '"•"',
    display: "inline-block",
    left: -64,
    position: "absolute",
    textAlign: "right",
    top: "-0.06em",
    width: 56,
  },
})

// Styles
const styles = theme =>
  createStyles({
    root: {
      display: "flex",

      "& h1": {
        ...theme.typography.h3,
        // ...arrow(theme),
      },
      "& h2": {
        ...theme.typography.h4,
        ...arrow(theme),
      },
      "& h3": {
        ...theme.typography.h5,
        ...arrow(theme),
      },
      "& h4": {
        ...theme.typography.h6,
        ...arrow(theme),
      },
      "& p": {
        ...theme.typography.body1,
      },
      "& strong": {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    content: {
      padding: theme.spacing(16, 0, 6),
      flexGrow: 1,

      "& a": {
        color: "inherit",
        borderBottom: "1px solid #e60d7f",
        textDecoration: "none",
      },

      "& ul": {
        ...theme.typography.body1,
        listStyleType: "none",
        marginBottom: 24,
      },
      "& ul ul": {
        ...theme.typography.body1,
        listStyleType: "none",
        marginBottom: 0,
      },
      "& li": {
        marginBottom: 8,
        ...li1(theme),
      },
      "& li li": {
        marginBottom: 8,
        ...li2(theme),
      },
    },
    contentInner: {
      padding: theme.spacing(2),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        flexGrow: 1,
        margin: "0 auto",
        padding: theme.spacing(3),
        maxWidth: 800,
        zIndex: 1, // Without this, right side of content is unclickable.
      },
    },
    // toolbar: theme.mixins.toolbar,
    drawer: {
      [theme.breakpoints.up("md")]: {
        width: drawerWidth,
      },
      flexShrink: 0,
    },
    drawerContent: {
      padding: theme.spacing(0, 3, 3),
    },
    drawerPaper: {
      backgroundColor: theme.palette.background.default,
      border: 0,
      paddingBottom: theme.spacing(4),
      // width: widthSm,
      [theme.breakpoints.up("md")]: {
        width,
      },
    },
    appBar: {
      backgroundColor: theme.palette.background.default,
      flexGrow: 1,
      zIndex: theme.zIndex.drawer + 5000,
    },
    logo: {
      // display: "none",
      lineHeight: "0",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
      "& svg": {
        fill: "#fff",
        height: 16,
      },
    },
    menuButton: {
      marginRight: theme.spacing(1),
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    title: {
      flexGrow: 1,
      fontWeight: 600,
      paddingLeft: 16,
    },
    footer: {
      color: theme.palette.grey[300],
      padding: theme.spacing(6, 0),
      textAlign: "center",

      "& a": {
        color: "inherit",
        borderBottom: "1px solid #e60d7f",
        textDecoration: "none",
      },
    },
  })

// //
const Main = props => {
  const { classes, children } = props

  const [mobileOpen, setMobileOpen] = React.useState(false)

  const [hoverRef, isHovered] = useHover()
  const rootClasses = clsx(classes.root, isHovered && classes.rootHover)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleDrawerOpen = () => {
    setMobileOpen(true)
  }

  const handleDrawerClose = () => {
    setMobileOpen(false)
  }

  return (
    <>
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar} elevation={5}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <span className={classes.logo}>
              <Link to="/">
                <Logo />
              </Link>
            </span>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden mdUp implementation="css">
            <SwipeableDrawer
              className={rootClasses}
              variant="temporary"
              anchor="left"
              open={mobileOpen}
              onClose={handleDrawerClose}
              onOpen={handleDrawerOpen}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <DrawerInner handleDrawerClose={handleDrawerClose} />
            </SwipeableDrawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              className={rootClasses}
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              ref={hoverRef}
            >
              <DrawerInner handleDrawerClose={handleDrawerClose} />
            </Drawer>
          </Hidden>
        </nav>
        <Container component="main" className={classes.content}>
          <div className={classes.contentInner}>{children}</div>
        </Container>
      </div>
      <footer className={classes.footer}>
        <Typography variant="caption">
          © {new Date().getFullYear()}
          {` `}
          <a href="https://www.instrumentor.ch">Instrumentor</a>
        </Typography>
      </footer>
    </>
  )
}

export default withStyles(styles)(Main)
